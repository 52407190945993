import Appointment from "./Appointment";
import Calendly from "./Calendly";
import ChatBox from "./ChatBox";
import Dashboard from "./Dashboard";
import Home from "./Home";
import LawyerDetails from "./LawyerDetails";
import LawyerList from "./LawyerList";
import LawyerProfileCard from "./LawyerProfileCard";
import Sidebar from "./Sidebar";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import TableList from "./TableList";

export {
  Home,
  Dashboard,
  LawyerList,
  LawyerDetails,
  Sidebar,
  TableList,
  Appointment,
  LawyerProfileCard,
  Calendly,
  ChatBox,
  SignUp,
  SignIn,
};
