import React from "react";
import { Link } from "react-router-dom";
import { MdEmail, MdPassword } from "react-icons/md";
import { FaSignInAlt } from "react-icons/fa";

const SignIn = () => (
  <div className="signin-wrapper">
    <form className="signin-form">
      <h2 className="flex gap-2 justify-center items-center text-3xl text-bold text-[#E9FE53] my-3">
        <FaSignInAlt size={32} color="#E9FE53" />
        Sign In
      </h2>
      <div className="signin-input-wrapper">
        <MdEmail className="signin-icon" color="#E9FE53" size={18} />
        <input
          className="signin-input"
          type="text"
          name="email"
          placeholder="Enter Your email..."
          autoComplete="off"
        />
      </div>

      <div className="signin-input-wrapper">
        <MdPassword className="signin-icon" color="#E9FE53" size={18} />

        <input
          className="signin-input"
          type="password"
          name="password"
          placeholder="Enter Your Password..."
        />
      </div>
      <button className="signin-btn" type="submit">
        Signin
      </button>
      <span className="signin-info">
        <p>
          Dont have an account?{" "}
          <Link className="link" to="/register">
            Signup here!
          </Link>
        </p>
      </span>
    </form>
  </div>
);

export default SignIn;
