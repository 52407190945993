import React from "react";
import PropTypes from "prop-types";

const Wrapper = ({ children }) => (
  <div className="sm:max-h-[98vh] w-full overflow-hidden">{children}</div>
);

Wrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
export default Wrapper;
