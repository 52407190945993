import React from "react";
import { InlineWidget } from "react-calendly";

const Calendly = () => (
  <div className="appointment-book-wrapper">
    <header className="head">
      <h2 className="font-bold">Book Appointment</h2>
    </header>

    <InlineWidget
      url={`${process.env.REACT_APP_CALENDLY_API_URL}/consultation`}
      className="calendly-component"
      styles={{
        height: "100%",
        position: "relative",
        top: "-60px",
      }}
      pageSettings={{
        backgroundColor: "#1C1D23",
        primaryColor: "#E9FE53",
        textColor: "#ffffff",
        // hideEventTypeDetails: true,
        // hideLandingPageDetails: true,
      }}
    />
  </div>
);

export default Calendly;
