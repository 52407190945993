import React, { useState } from "react";
import { IoSend } from "react-icons/io5";

import { BeatLoader } from "react-spinners";
import { FaMicrophone } from "react-icons/fa";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addNewChat } from "../features/chatSlice";
import ChatBox from "./ChatBox";

const Dashboard = () => {
  const [query, setQuery] = useState("");
  const [input, setInput] = useState("");

  const [loading, setLoading] = useState(false);

  const { chats } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setQuery(e.target.value);
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fetchResponse = async () => {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/custom_chat?id=1`,
        { query }
      );

      const handleAddChat = (ans) => {
        const chatObj = {
          id: uuidv4(),
          userQuery: query,
          botResponse: ans,
        };

        dispatch(addNewChat(chatObj));
      };

      handleAddChat(data);
      setLoading(false);
      setQuery("");
    };

    fetchResponse();
  };

  const handleEdit = () => {
    setQuery(input);
  };

  return (
    <main className="dashboard-wrapper">
      <div className="chat-box">
        {chats.length > 0 &&
          chats.map((chat) => (
            <ChatBox key={chat.id} chat1={chat} handleEdit={handleEdit} />
          ))}
      </div>

      <form className="chat-form" onSubmit={handleSubmit}>
        <input
          className="chat-input"
          name="chatInput"
          value={query}
          type="text"
          onChange={handleChange}
          disabled={loading}
          autoComplete="off"
          placeholder="Write a prompt..."
        />
        <div className="mic">
          <FaMicrophone size={25} className="cursor-pointer p-1" />
        </div>
        {loading ? (
          <div className="loader">
            <BeatLoader
              color="#f1f1f1"
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="inp-btn">
            <IoSend
              className="bg-[#E9FE53] p-1 cursor-pointer"
              color="black"
              size={25}
              onClick={handleSubmit}
            />
          </div>
        )}
      </form>
    </main>
  );
};

export default Dashboard;
