import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import {
  Appointment,
  Calendly,
  Dashboard,
  LawyerDetails,
  LawyerList,
  SignUp,
  SignIn,
  Home,
} from "../components";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: "/",
            element: <Dashboard />,
          },

          {
            path: "/lawyers",
            element: <LawyerList />,
          },
          {
            path: "/appointment",
            element: <Appointment />,
          },
          {
            path: "/lawyer/:id",
            element: <LawyerDetails />,
          },

          {
            path: "/schedule-appointment",
            element: <Calendly />,
          },
        ],
      },
      {
        path: "/register",
        element: <SignUp />,
      },
      {
        path: "/signin",
        element: <SignIn />,
      },
    ],
  },
]);

export default router;
