import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Fade } from "react-reveal";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FaUser } from "react-icons/fa";

const LawyerDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fullBio, setFullBio] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLawyerDetails = async () => {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/lawyer_details`,
        {
          lawyer_id: id,
        }
      );
      setData(res.data);
      setLoading(false);
    };
    fetchLawyerDetails();
  }, []);

  console.log(data.lawyer_img ? "0" : "else");
  return (
    <div className="lawyer-profile-wrapper">
      <header className="head">
        <h2 className="font-bold">Lawyer Profile</h2>
      </header>
      {loading ? (
        <BeatLoader
          className="flex justify-center items-center"
          color="#f1f1f1"
          loading={loading}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        // <Fade bottom>
        <div className="lawyer-profile-box">
          <div className="profile">
            {data.lawyer_img ? (
              <img
                src={data.lawyer_img}
                className="p-1 rounded-full bg-slate-300 sm:h-52 h-36"
                alt={data.lawyer_name}
              />
            ) : (
              <FaUser size={180} className="p-1 rounded-full bg-slate-300 " />
            )}

            <div className="basic-details">
              <h2 className="text-2xl font-semibold tracking-wide">
                {data.lawyer_name}
              </h2>
              <h5 className="text-sm text-[#E9FE53]">
                Contact- {data.lawyer_phone}
              </h5>
              <h5 className="text-xs text-[#E9FE53]">
                Exp- {data.lawyer_exp} years
              </h5>
              <div className="address-detail max-w-[87%] text-[#B6B6B6] py-2">
                {data.lawyer_address}
              </div>
              <button
                type="submit"
                className="book-appointment-btn"
                onClick={() => navigate("/schedule-appointment")}
              >
                Book Appointment
              </button>
            </div>
          </div>
          <div className="other-details">
            <h3 className="detail">
              <span className="text-[#B7B7B7]">Bio-</span>{" "}
              <span>
                {/* {data.lawyer_bio} */}
                {fullBio ? data.lawyer_bio : data.lawyer_bio.substring(0, 400)}
              </span>
              {!fullBio ? (
                <span
                  className="text-xs text-slate-500 cursor-pointer"
                  onClick={() => setFullBio(!fullBio)}
                >
                  ...View More
                </span>
              ) : (
                <span
                  className="text-xs text-slate-500 cursor-pointer"
                  onClick={() => setFullBio(!fullBio)}
                >
                  ...View Less
                </span>
              )}
            </h3>{" "}
            <h3 className="detail">
              <span className="text-[#B7B7B7]">
                Education & Qualifications-
              </span>{" "}
              {data.lawyer_law_school}
            </h3>
            <h3 className="detail">
              <span className="text-[#B7B7B7]">Practice Areas-</span>{" "}
              {data.lawyer_practices}
            </h3>
            <h3 className="detail">
              <span className="text-[#B7B7B7]">Consultation Fees-</span>{" "}
              {data.lawyer_fees}
            </h3>
          </div>
        </div>
        // </Fade>
      )}
    </div>
  );
};

export default LawyerDetails;
