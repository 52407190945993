import React, { useEffect, useState } from "react";
import axios from "axios";

const Appointment = () => {
  const [appointments, setAppointments] = useState([]);
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await axios.get(
          "https://api.calendly.com/scheduled_events",
          {
            headers: {
              Authorization:
                "Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg1MzYxMjI0LCJqdGkiOiI0ZTdhZTNmMC1jOTRhLTRiYzEtODhhNi02ZDVkMTQxNzgyNGEiLCJ1c2VyX3V1aWQiOiJiNzRlODJjOS0zYzMxLTQ1ZTktODE2YS1iOGIyNTU4NDE2N2MifQ.jbaJZMvLYNQBHKcY3pEQXM4TF0Z6vh1VOJQWZHy5d25mmS5bxJO9XRthlqTs9KhDOXYif4wuSdVr8sSqHRUsyA",
              "Content-Type": "application/json",
            },
          }
        );
        setAppointments(response.data.collection);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };
    fetchAppointments();
  }, []);
  console.log(appointments);
  return (
    <div className="appointment-wrapper">
      <header className="head">
        <h2 className="font-bold">My Appointments</h2>
        <button
          type="button"
          className="book-appointment-btn sm:text-base text-sm"
        >
          + Book New Appointment
        </button>
      </header>
    </div>
  );
};

export default Appointment;

// MRHPQBQBIROOI2JADFT47ECHXCSTPI4U

// token->eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjg1MzYxMjI0LCJqdGkiOiI0ZTdhZTNmMC1jOTRhLTRiYzEtODhhNi02ZDVkMTQxNzgyNGEiLCJ1c2VyX3V1aWQiOiJiNzRlODJjOS0zYzMxLTQ1ZTktODE2YS1iOGIyNTU4NDE2N2MifQ.jbaJZMvLYNQBHKcY3pEQXM4TF0Z6vh1VOJQWZHy5d25mmS5bxJO9XRthlqTs9KhDOXYif4wuSdVr8sSqHRUsyA
