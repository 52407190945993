import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { GiSpeaker } from "react-icons/gi";
import { AiFillCopy, AiFillLike, AiFillDislike } from "react-icons/ai";
import { BeatLoader } from "react-spinners";
import { FaEdit, FaUser } from "react-icons/fa";
import logo from "../assets/court-gpt-logo.svg";
import LawyerProfileCard from "./LawyerProfileCard";

const ChatBox = ({ chat1, handleEdit }) => {
  console.log(chat1);

  const [loading, setLoading] = useState(false);
  const [showRecommend, setShowRecommend] = useState(false);

  const [recommends, setRecommends] = useState([]);

  const handleRecommendLawyer = () => {
    async function fetchRecommendedLawyer() {
      setLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/recommend`,
        {
          query: chat1.userQuery,
        }
      );
      setRecommends(data);
      setLoading(false);
      setShowRecommend(true);
    }
    fetchRecommendedLawyer();
  };

  return (
    <div>
      {chat1 && (
        <div key={chat1.userQuery} className="chat">
          <div className="user-chat">
            <FaUser size={32} className="p-1 rounded-full bg-slate-700" />

            {/* <img
                    className="w-20 object-contain rounded-full bg-slate-400 "
                    src=""
                    alt="user"
                  /> */}
            <div className="query">{chat1.userQuery}</div>
            <FaEdit
              className="options cursor-pointer hover:text-yellow-200"
              onClick={handleEdit}
            />
          </div>

          <div className="bot-chat">
            <div className="bot-top-chat">
              <img
                className="w-8   object-contain rounded-full bg-slate-400"
                src={logo}
                alt="bot"
              />
              {chat1.botResponse && (
                <div className="response">{chat1.botResponse.response}</div>
              )}
              <div className="options flex flex-col md:gap-4 gap-3">
                <AiFillLike className="cursor-pointer hover:text-yellow-200" />
                <AiFillDislike className="cursor-pointer hover:text-yellow-200" />
                <GiSpeaker
                  size={20}
                  className="cursor-pointer hover:text-yellow-200"
                />
                <AiFillCopy
                  size={15}
                  className="cursor-pointer hover:text-yellow-200"
                />
              </div>
            </div>

            {!showRecommend ? (
              <button
                type="button"
                className="recommend-btn text-sm py-1 my-4 max-w-max"
                onClick={handleRecommendLawyer}
              >
                {loading ? (
                  <BeatLoader
                    className="flex justify-center items-center"
                    color="red"
                    loading={loading}
                    size={8}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  "Recommend Lawyer"
                )}
              </button>
            ) : (
              <div className="lawyer-card-wrapper">
                {recommends.map((lawyer) => (
                  <LawyerProfileCard key={lawyer.lawyer_id} {...lawyer} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ChatBox.propTypes = {
  chat1: PropTypes.object,
  handleEdit: PropTypes.func,
};
ChatBox.defaultProps = {
  chat1: "",
  handleEdit: "",
};

export default ChatBox;
