import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FaGripVertical,
  FaRegAddressBook,
  FaUser,
  FaArrowUp,
  FaRegClock,
} from "react-icons/fa";

import { MdSpaceDashboard } from "react-icons/md";
import logo from "../assets/court-gpt-logo.svg";
import RecentChats from "./RecentChats";
import { clearChat } from "../features/chatSlice";

const Sidebar = () => {
  const [dropUp, setDropUp] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showDropUp = () => {
    setDropUp(!dropUp);
  };

  const handleNewChat = () => {
    navigate("/");
    dispatch(clearChat());
  };

  const handleLogout = () => {
    navigate("/signin");
  };

  return (
    <header className="sidebar-wrapper">
      <div className="sidebar-upper flxCol s-p border-b-[1px] border-b-[#363636]">
        <div
          className="flex items-center gap-2 mb-2 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={logo} className="md:w-12 w-10 mr-1" alt="logo" />
          <h2 className="sm:text-2xl text-base font-bold logo-text">
            Court-GPT
          </h2>
        </div>
        <ul>
          <li className="nav-item" onClick={() => navigate("/")}>
            <MdSpaceDashboard size={22} />
            <h3 className="nav-text">Dashboard</h3>
          </li>
          <li className="nav-item" onClick={() => navigate("/lawyers")}>
            <FaRegAddressBook size={22} />
            <h3 className="nav-text">Lawyer Directory</h3>
          </li>
          <li className="nav-item">
            <FaRegClock size={22} />
            <h3 className="nav-text">Appointments</h3>
          </li>
        </ul>
      </div>

      {/* to be done yet.. */}
      <div className="sidebar-mid flxCol s-p">
        <span className="flex justify-between items-center cursor-pointer">
          <h2 className=" font-medium">Recent Chats</h2>
          <FaArrowUp c size={10} />
        </span>
        <RecentChats />
      </div>

      <div className="sidebar-lower flxCol s-p">
        <button type="button" className="new-chat-btn" onClick={handleNewChat}>
          + New Chat
        </button>
        <div className="user-card">
          <FaUser size={32} className="p-1 rounded-full bg-slate-700" />
          <div className="user-detail">
            <h2 className=" font-bold">Aditya Puri</h2>
            <h3 className="text-[#D1D1D1] text-sm">aditya@wow.com</h3>
          </div>

          {dropUp && (
            <div className="drop">
              <button
                type="button"
                className="logout-btn"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}

          <FaGripVertical
            className="cursor-pointer"
            size={20}
            onClick={showDropUp}
          />
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
