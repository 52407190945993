import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chats: [],
};

const chatSlice = createSlice({
  name: "chat",
  initialState,

  reducers: {
    addNewChat: (state, action) => {
      state.chats.push(action.payload);
    },
    clearChat: (state) => ({ ...state, chats: [] }),
  },
});

export const { addNewChat, clearChat } = chatSlice.actions;
export default chatSlice.reducer;
