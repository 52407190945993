import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FaUser } from "react-icons/fa";

const LawyerProfileCard = ({
  lawyer_name,
  lawyer_phone,
  lawyer_exp,
  lawyer_website,
  lawyer_role,
  lawyer_id,
  lawyer_img,
}) => {
  const navigate = useNavigate();
  return (
    <div className="lawyer-card">
      <div className="banner" />
      {lawyer_img ? (
        <img src={lawyer_img} className="lawyer-img" alt="lawyer-img" />
      ) : (
        <FaUser className="lawyer-img" />
      )}
      <div className="card-details ">
        <div className="card-detail">
          <span className="text-slate-500">Name:</span>
          <span>{lawyer_name}</span>
        </div>
        <div className="card-detail">
          <span className="text-slate-500">Contact:</span>
          <span>{lawyer_phone}</span>
        </div>
        <div className="card-detail">
          <span className="text-slate-500">Website:</span>
          <span>{lawyer_website}</span>
        </div>
        <div className="card-detail">
          <span className="text-slate-500">Experience:</span>
          <span>
            {lawyer_role}
            <br />
            {lawyer_exp}
          </span>
        </div>
        <button
          type="button"
          className="view-profile-btn text-sm py-1 w-max"
          onClick={() => navigate(`lawyer/${lawyer_id}`)}
        >
          View Profile
        </button>
      </div>
    </div>
  );
};

LawyerProfileCard.propTypes = {
  lawyer_name: PropTypes.string,
  lawyer_phone: PropTypes.string,
  lawyer_exp: PropTypes.number,
  lawyer_website: PropTypes.string,
  lawyer_role: PropTypes.string,
  lawyer_id: PropTypes.string,
  lawyer_img: PropTypes.string,
};

LawyerProfileCard.defaultProps = {
  lawyer_name: "",
  lawyer_phone: "",
  lawyer_exp: 0,
  lawyer_website: "",
  lawyer_role: "",
  lawyer_id: "",
  lawyer_img: "",
};

export default LawyerProfileCard;
