import React, { useState } from "react";

const RecentChats = () => {
  const [selected, setSelected] = useState(false);

  const handleRecentChat = (e) => {
    setSelected(e.target.key);
  };

  return (
    <ul className="recentBox flex flex-col transition-all">
      <li
        key="1"
        className={`${
          selected && "bg-slate-700"
        } recentItem flex flex-col my-2`}
        onClick={handleRecentChat}
      >
        <span className="flex justify-between md:text-base text-sm">
          <h3 className="">What docs and evid....</h3>
          <p className="text-sm">2hrs</p>
        </span>
        <span className="text-sm mt-2 text-slate-400 ">
          To support a home agreement case, you will nedd to gather several
          documents....
        </span>
      </li>
      <li
        key="2"
        className={`${
          selected && "bg-slate-700"
        } recentItem flex flex-col my-2`}
        onClick={handleRecentChat}
      >
        <span className="flex justify-between md:text-base text-sm">
          <h3 className="">What docs and evid....</h3>
          <p className="text-sm">2hrs</p>
        </span>
        <span className="text-sm mt-2 text-slate-400 ">
          To support a home agreement case, you will nedd to gather several
          documents....
        </span>
      </li>
      <li
        key="3"
        className={`${
          selected && "bg-slate-700"
        } recentItem flex flex-col my-2`}
        onClick={handleRecentChat}
      >
        <span className="flex justify-between md:text-base text-sm">
          <h3 className="">What docs and evid....</h3>
          <p className="text-sm">2hrs</p>
        </span>
        <span className="text-sm mt-2 text-slate-400 ">
          To support a home agreement case, you will nedd to gather several
          documents....
        </span>
      </li>
    </ul>
  );
};

export default RecentChats;
