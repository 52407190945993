import React from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";

const TableList = ({
  lawyer_name,
  lawyer_state,
  lawyer_id,
  lawyer_img,
  lawyer_phone,
  lawyer_website,
  lawyer_role,
  lawyer_exp,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="lawyer-detail text-[15px] cursor-pointer"
      onClick={() => navigate(`/lawyer/${lawyer_id}`)}
    >
      <img
        src={lawyer_img}
        className="sm:h-14 h-12 object-fit rounded-full"
        alt={lawyer_name}
      />
      <div className="table-col">
        <h2 className="font-medium">{lawyer_name}</h2>
        <span className="flex gap-1 items-center">
          <FaMapMarkerAlt size={12} />
          <h2 className="text-[13px] text-[#B1B1B1]">
            {lawyer_state.toUpperCase()}
          </h2>
        </span>
      </div>
      <div className="table-col">
        <h2>{lawyer_role}</h2>
        <span className="flex gap-1 items-center">
          <h2 className="text-[13px] text-[#B1B1B1]">Exp. {lawyer_exp}</h2>
        </span>
      </div>
      <div className="table-col">
        <h2 className="text-[13px] text-[#B1B1B1]">{lawyer_website}</h2>
      </div>
      <div className="table-col">
        <h2 className="text-[13px] text-[#B1B1B1]">{lawyer_phone}</h2>
      </div>
    </div>
  );
};

TableList.propTypes = {
  lawyer_name: PropTypes.string,
  lawyer_state: PropTypes.string,
  lawyer_id: PropTypes.number,
  lawyer_img: PropTypes.string,
  lawyer_phone: PropTypes.number,
  lawyer_website: PropTypes.string,
  lawyer_role: PropTypes.string,
  lawyer_exp: PropTypes.number,
};

TableList.defaultProps = {
  lawyer_name: "",
  lawyer_state: "",
  lawyer_id: "",
  lawyer_img: "",
  lawyer_phone: "",
  lawyer_website: "",
  lawyer_role: "",
  lawyer_exp: 0,
};

export default TableList;
