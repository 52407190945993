import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { FaSearch } from "react-icons/fa";
import TableList from "./TableList";
// import { Fade } from "react-reveal";
const LawyerList = () => {
  const [selectedBtn, setSelectedbtn] = useState("all-lawyers");
  const [lawyerList, setLawyerList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [searchedLawyerList, setSearchedLawyerList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLawyers = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/list_lawyers`
      );

      setLoading(false);
      setLawyerList(res.data);
    };
    fetchLawyers();
  }, []);
  useEffect(() => {
    const searchResult = lawyerList.filter(
      (lawyer) =>
        lawyer.lawyer_name.toLowerCase().includes(searchQuery) ||
        lawyer.lawyer_role.toLowerCase().includes(searchQuery) ||
        lawyer.lawyer_state.toLowerCase().includes(searchQuery) ||
        lawyer.lawyer_website.toLowerCase().includes(searchQuery)
    );

    setSearchedLawyerList(searchResult);
  }, [searchQuery]);

  const handleSelectLawyer = (e) => {
    setSelectedbtn(e.target.id);
  };

  const navigate = useNavigate();

  const handleSearchList = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="lawyerlist-wrapper">
      <div className="lawyers-input-wrapper">
        <FaSearch className="search-icon" />
        <input
          className="lawyer-search-input"
          type="text"
          alt="search-lawyers"
          placeholder="Search"
          onChange={handleSearchList}
        />
      </div>
      <div className="selectLawyer-btns">
        <button
          id="all-lawyers"
          type="button"
          className={`${
            selectedBtn === "all-lawyers" &&
            "border-b-[1px]  border-b-[#E9FE53] bg-[#1C1D23]"
          } selectLawyer-btn`}
          onClick={handleSelectLawyer}
        >
          All Lawyers ({lawyerList ? lawyerList.length : 0})
        </button>
        {/* <button
          id="consulted-lawyers"
          className={`${
            selectedBtn === "consulted-lawyers" &&
            "border-b-[1px] border-b-[#E9FE53] bg-[#1C1D23]"
          } selectLawyer-btn`}
          onClick={handleSelectLawyer}
        >
          Consulted (2)
        </button> */}
      </div>
      {selectedBtn === "all-lawyers" ? (
        <div className="table-wrapper">
          {loading && (
            <BeatLoader
              className="flex justify-center items-center"
              color="#f1f1f1"
              loading={loading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {lawyerList.length > 0 && searchedLawyerList.length > 0
            ? searchedLawyerList.map((lawyer, index) => (
                // <Fade top>
                <TableList
                  key={lawyer.lawyer_id}
                  {...lawyer}
                  index={index}
                  navigate={navigate}
                />
                // </Fade>
              ))
            : lawyerList.map((lawyer, index) => (
                // <Fade top>
                <TableList
                  key={lawyer.lawyer_id}
                  {...lawyer}
                  index={index}
                  navigate={navigate}
                />
                // </Fade>
              ))}
        </div>
      ) : (
        <div className="table-wrapper">
          <TableList />
        </div>
      )}
    </div>
  );
};

export default LawyerList;
