import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Wrapper from "./Wrapper";

const Home = () => (
  <>
    <Sidebar />
    <Wrapper>
      <Outlet />
    </Wrapper>
  </>
);

export default Home;
