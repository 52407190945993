import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { FaSignInAlt } from "react-icons/fa";
import { MdEmail, MdPassword } from "react-icons/md";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="signup-wrapper">
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="flex gap-2 justify-center items-center text-3xl text-bold text-[#E9FE53] my-3">
          <FaSignInAlt size={32} color="#E9FE53" />
          Sign Up
        </h2>
        <div className="signup-input-wrapper">
          <MdEmail className="signup-icon" color="#E9FE53" size={18} />
          <input
            className={`signup-input ${errors.email && "invalid-input"}`}
            type="email"
            {...register("email")}
            placeholder="Enter email..."
            autoComplete="off"
          />
        </div>
        {errors.email && (
          <span className="error-message">{errors.email.message}</span>
        )}

        <div className="signup-input-wrapper">
          <MdPassword className="signup-icon" color="#E9FE53" size={18} />
          <input
            className={`signup-input ${errors.password && "invalid-input"}`}
            type="password"
            {...register("password")}
            placeholder="Enter Password..."
          />
        </div>
        {errors.password && (
          <span className="error-message">{errors.password.message}</span>
        )}

        <div className="signup-input-wrapper">
          <MdPassword className="signup-icon" color="#E9FE53" size={18} />
          <input
            className={`signup-input ${
              errors.confirmPassword && "invalid-input"
            }`}
            type="password"
            {...register("confirmPassword")}
            placeholder="Confirm Password..."
          />
        </div>
        {errors.confirmPassword && (
          <span className="error-message">
            {errors.confirmPassword.message}
          </span>
        )}
        <button className="signup-btn" type="submit">
          Signup
        </button>
        <span className="signin-info">
          <p>
            Already have an account?{" "}
            <Link className="link" to="/signin">
              Signin here!
            </Link>
          </p>
        </span>
      </form>
    </div>
  );
};

export default SignUp;
